.edit-post-section {
    margin-top: 20px;
    .edit-post-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  
    .ant-upload-drag {
      background-color: #f0f2f5; // Màu nền của khung
      border: 2px dashed #d9d9d9; // Đường viền dạng dashed
      padding: 20px;
      border-radius: 8px; // Góc bo tròn cho khung
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #e6f7ff; // Màu nền khi hover
      }
  
      .ant-upload-drag-icon {
        font-size: 32px;
        color: #1890ff; // Màu icon
      }
  
      .ant-upload-text {
        font-size: 14px;
        color: #595959; // Màu chữ
        margin-top: 8px;
      }
    }
  
    .existing-thumbnail-container {
      margin-top: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    }
  
    .existing-thumbnail-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center;
    }
  
    .delete-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(255, 255, 255, 0.8);
      border: none;
    }
  }
  .dev{
    background-color: #d9d9d9;
  }
  .edit-post-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  /* Phần chọn ảnh bài viết */
  .thumbnail-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    background-color: #f7f7f7; /* Nền màu xám sáng cho phần chọn ảnh */
    padding: 15px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .thumbnail-image {
    border: 1px solid #d9d9d9;
    padding: 10px;
  }

  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    background: rgba(255, 255, 255, 0.8);
    border: none;
  }

  .thumbnail-container:hover .delete-button {
    display: block;
  }

  .upload-dragger .ant-upload-drag-icon,
  .upload-dragger .ant-upload-text {
    color: #595959;
  }

  /* Phần ảnh hiện có */
  .existing-thumbnail-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: #ffffff; /* Nền trắng cho phần ảnh hiện có */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  .existing-thumbnail-title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .existing-thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    padding: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .existing-thumbnail {
    border: none;
  }