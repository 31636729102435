.box {
  background: #fff;
}
.card-box {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: white;
  position: relative;
  height: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 30px;
  padding: 15px;
}

.title {
  font-size: 16px;
  color: #5a5a5a;
}

.value {
  font-size: 20px;
  font-weight: bold;
  color: #1e1e1e;
  width: 160px;
  white-space: normal;
  word-wrap: break-word;
}

.chart-box {
  width: 100%;
  height: 400px;
  padding: 20px;
  padding-bottom: 70px;
  background: #fff;
  border-radius: 10px;
}

.description {
  font-size: 14px;
  color: #9e9e9e;
}
.user-list-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-list-box h3 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
