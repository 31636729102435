/* Định dạng cho các nút */
.save-button {
  background-color: #0c9e89;
  border-color: #008000;
  color: white;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #088178 !important;
  border-color: #088178 !important;
}

.print-button {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.print-button:hover {
  background-color: #e0e0e0 !important;
  border-color: #bfbfbf !important;
  color: #333 !important;
}
