.notify {
  &__dropdown {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    min-width: 250px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  &__body {
    border-top: 1px solid #ddd;
    max-height: 230px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    } 
    &:where(.css-dev-only-do-not-override-ni1kz0).ant-dropdown-menu-vertical {
      overflow-y: unset !important;
    }

    & .ant-dropdown-menu {
      box-shadow: none;
    }

    & .ant-dropdown-menu-item {
      border-bottom: 1px solid #ddd;
    }
  }

  &__not {
    padding: 15px;
    color: #959595;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    &-icon {
      &-1, &-2, &-3 {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
      }
      &-1 {
        background: rgb(0 169 158 / 22%);
        & span {
          font-size: 24px;
          color: #00a99d;
        }
      }
      &-2 {
        background: rgb(255, 184, 184);
        & span {
          font-size: 24px;
          color: red;
        }
      }
      &-3 {
        background: #ffe5b5;
        & span {
          font-size: 24px;
          color: orange;
        }
      }
    }

    &-content {
      flex: 1;
    }

    &-title {
      font-size: 14px;
      font-weight: 600;
    }

    &-subtitle {
      width: 200px;
    }

    &-time {
      font-size: 12px;
    }
  }
}


