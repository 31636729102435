.ck-editor__editable_inline {
  min-height: 250px;
}

.btn-add-book {
  margin-left: 8px;
  background-color: #00a99d;
  color: #fff;
  border-radius: 8px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
}

.add-book {
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #334155;
  }
  &-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    margin-bottom: 20px;
  }
}
