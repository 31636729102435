.ant-table-cell {
    vertical-align: middle !important; 
  }
  .payment-detail-modal .ant-modal-body {
    font-size: 16px; 
    line-height: 1.8;
  }
  
  .payment-detail-modal p {
    margin-bottom: 12px; 
  }
  .payment-detail-modal p strong {
    font-size: 18px; 
  }
  