.order-list-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.order-list-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.order-list-subtitle {
  color: #888;
  margin-bottom: 20px;
}

.order-list-filters {
  margin-bottom: 20px;
}

.order-list-filters .ant-input,
.order-list-filters .ant-select {
  background-color: #f4f6f9;
  border: none;
  box-shadow: none;
}

.order-list-table {
  background-color: white;
  border-radius: 8px;
}

.ant-pagination-item {
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  background-color: #f4f6f9;
}

.ant-table-tbody > tr > td {
  background-color: white;
}

.ant-select-middle .ant-select-selector {
  height: 32px;
  padding: 0 8px;
  font-size: 14px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

// .ant-input {
//   height: 32px;
//   width: 100%;
//   max-width: 250px;
//   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
//   font-size: 14px;
// }

.right-align-filters {
  display: flex;
  justify-content: flex-end;
}

.right-align-filters .ant-select {
  margin-right: 10px;
}
