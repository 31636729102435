html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  outline: none;
  word-wrap: normal;
}

input {
  line-height: normal;
}

label,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

ul,
li {
  list-style: none;
}

/* Custom Scroll */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #00ab9f;
  /* border-radius: 100rem; */
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}