.delete-button-container {
  background-color: rgba(255, 0, 0, 0.1);
  padding: 8px;
  border-radius: 4px;
}

.delete-button {
  border: none;
}

.search-input {
  width: 300px;
  border: 1px solid #d9d9d9; 
  border-radius: 4px;
  padding: 8px; 
  transition: border-color 0.3s; 
}

.search-input:focus {
  border-color: #40a9ff; 
  outline: none; 
}

.search-button {
  margin-left: 10px;
}

figure.image img {
  width: 100% !important;
  height: auto !important;
  margin: 0 auto;
}
