.custom-avatar {
  img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  .ant-image-mask {
    border-radius: 50% !important; 
  }
}