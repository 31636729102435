.header {
  display: flex;
  border-bottom: 1px solid #ddd;
  background: #fff;

  &__logo {
    background: #00a99d;
    padding: 5px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
    transition: all 0.2s;

    &--collapsed {
      width: 80px;
      transition: all 0.2s;
    }
  }

  &__nav {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    &-left {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__collapse {
    margin-right: 20px;
    cursor: pointer;
  }
}

.ant-menu-item-selected {
  color: #00a99d !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-ni1kz0).ant-menu-light>.ant-menu .ant-menu-submenu-selected{
  color: #00a99d !important;
}

.content {
  padding: 20px;
}

.ant-menu-item, .ant-menu-submenu {
  svg {
    font-size: 16px !important;
  }
}

.menu-sider {
  position: sticky;
  top: 0;
}