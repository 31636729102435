.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f0f2f5; /* Light background color */
}

.login-right {
  width: 100%;
  max-width: 400px; /* Adjust the width of the card */
}

.login-card {
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-logo {
  display: block;
  margin: 0 auto 20px auto;
  width: 280px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.input-field {
  height: 40px;
}

.login-form-button {
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  background: #00a99d;
  &:hover {
    background: #008d85 !important;
  }
}

.sorry {
  display: none;
}

@keyframes marquee {
  0% {
    color: #ffd903;
  }
  50% {
    color: #00a99d;
  }
  100% {
    color: #ffd903;
  }
}

.marquee-text {
  animation: marquee 3s linear infinite;
  font-size: 28px;
  text-align: center;
  padding-top: 20px;
}

@media (max-width: 575.98px) {
  .sorry {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }

  .layout,
  .login-page {
    display: none !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sorry {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }

  .layout,
  .login-page {
    display: none !important;
  }
}
