.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
}

.step-icon-finished {
  background-color: #00ab9f;
}

.step-icon-in-progress {
  background-color: #008c7a;
}

.step-icon-waiting {
  background-color: #ccc;
}

.step-title-finished,
.step-title-in-progress {
  color: #00ab9f;
}

.step-title-waiting {
  color: #ccc;
}

.steps-margin {
  margin-bottom: 20px;
  margin-top: 20px;
}

.primary {
  background-color: #00ab9f;
  transition: background-color 0.3s;
  margin-bottom: 20px;
  margin-top: 20px;
}

.primary:hover {
  background-color: #008c7a !important;
}
